import { useRef, useState, useEffect, useCallback } from 'react';

export const useAsyncState = (init) => {
  const isMounted = useRef(true);
  const [state, setState] = useState(init);
  const checkIsMounted = useCallback(() => isMounted.current, []);

  const updateState = useCallback((data) => {
    if (isMounted.current) {
      setState(data);
    }
  }, []);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return [state, updateState, checkIsMounted];
};

import React from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import { useLoggedIn } from 'store/session';
import { WRITING } from 'store/writings';

import Header from './Header';
import Navbar from './Navbar';
import { Main } from './styles';

const Layout = () => {
  const isLoggedIn = useLoggedIn();
  const isHomePage = useMatch('/');
  const isProfilePage = useMatch('/profile');
  const isSupportPage = useMatch('/support');
  const isWritingsPage = useMatch(`/${WRITING}`);
  const withHeader = Boolean(isHomePage || isProfilePage || isSupportPage || isWritingsPage);

  return (
    <>
      {withHeader && <Header />}
      {isLoggedIn && <Navbar />}
      <Main $withHeader={withHeader} $isLoggedIn={isLoggedIn} $isHomePage={isHomePage}>
        <Outlet />
      </Main>
    </>
  );
};

export default Layout;

import { handleActions } from 'redux-actions';
import { pipe, pathOr, filter } from 'ramda';

import { SET_TASK, SET_TASKS, UPDATE_TASK, UPDATE_CHAPTERS_STATUS } from './types';
import { NAME, CHAPTERS, STATUS } from './consts';

export default handleActions(
  {
    [SET_TASK]: (state, { payload }) => ({
      ...state,
      [payload[NAME]]: payload,
    }),
    [SET_TASKS]: (state, { payload }) => payload,
    [UPDATE_TASK]: (state, { payload }) => ({
      ...state,
      [payload[NAME]]: {
        ...state[payload[NAME]],
        ...payload,
      },
    }),
    [UPDATE_CHAPTERS_STATUS]: (state, { payload }) => ({
      ...state,
      [payload[NAME]]: {
        ...state[payload[NAME]],
        [CHAPTERS]: [
          ...pipe(
            pathOr([], [payload[NAME], CHAPTERS]),
            filter(({ [NAME]: name }) => name !== payload.taskId)
          )(state),
          { [NAME]: payload.taskId, [STATUS]: payload[STATUS] },
        ],
      },
    }),
  },
  {}
);

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import SelectDef from 'components/Select';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: ${({ $isFixed }) => ($isFixed ? 'fixed' : 'sticky')};
  inset: 0 0 auto 0;
  height: var(--header-height);
  width: 100%;
  background-color: var(--color-white);
  z-index: 10;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  padding: ${({ $isHomePage, $isLoggedIn }) => ($isHomePage && '0 15px 0 215px') || ($isLoggedIn ? '0 15px 0 60px' : '0 15px 0')};
`;

export const Select = styled(SelectDef)`
  width: 55px;
  & > select {
    border: none;
  }
`;

export const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
`;

export const NavLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  min-width: 24px;
  min-width: 24px;
`;

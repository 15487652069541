import { useCallback, useEffect } from 'react';

import { wrapActions, wrapMultiSelector } from 'store/utils';
import { START_AFTER, useAlerts } from 'store/tasks';
import { useAsyncState } from 'utils/hooks';
import { PAGINATION } from 'api/consts';

import * as actions from './actions';
import { getWritingProp, getWriting, getFilter, getFilters } from './selectors';

export const useWritingsActions = wrapActions({ ...actions });

export const useWriting = wrapMultiSelector(getWritingProp, getWriting);
export const useFilters = wrapMultiSelector(getFilter, getFilters);

export const useWritings = (params) => {
  const [list, setList] = useAsyncState(null);
  const { fetchWritings } = useWritingsActions();
  const { action, loading, success, error, resetAlerts } = useAlerts(fetchWritings);
  const { [START_AFTER]: startAfter } = useFilters(PAGINATION);
  const refetch = useCallback(() => action(params), [action, params]);

  useEffect(() => {
    action(params);
  }, [action, params]);

  useEffect(() => {
    if (success) {
      setList(($) => (startAfter ? [...$, ...success] : success));
      resetAlerts();
    }
  }, [resetAlerts, setList, startAfter, success]);

  return { list: list || [], loading: loading || !list, error, refetch };
};

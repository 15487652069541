import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { LANGUAGES } from './consts';
import { Select } from './styles';

const LangSelect = ({ className = null }) => {
  const { t, i18n } = useTranslation('common');
  const onSelect = useCallback((lng) => i18n.changeLanguage(lng), [i18n]);

  return (
    <Select
      options={LANGUAGES}
      value={i18n.language}
      onSelect={onSelect}
      placeholder={t('Select language')}
      className={className}
    />
  );
};

LangSelect.propTypes = { className: PropTypes.string };

export default LangSelect;

import {
  INTRODUCTION,
  STRUCTURE,
  W_QUESTION,
  COOKBOOK,
  EQUATOR_NETWORK,
  REPORTING_GUIDLINES,
  SEARCH_LITERATURE,
} from 'store/tasks';
import { WRITING } from 'store/writings';
import introductionIcon from 'assets/svg-icons/introductionIcon.svg';
import structureIcon from 'assets/svg-icons/structureIcon.svg';
import rewritingIcon from 'assets/svg-icons/rewriting.svg';

export const ICONS = {
  [INTRODUCTION]: introductionIcon,
  [STRUCTURE]: structureIcon,
  [W_QUESTION]: structureIcon,
  [COOKBOOK]: structureIcon,
  [EQUATOR_NETWORK]: structureIcon,
  [REPORTING_GUIDLINES]: structureIcon,
  [SEARCH_LITERATURE]: structureIcon,
  [WRITING]: rewritingIcon,
};

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { GlobalStyles, Container, Title, Text, Restart } from './styles';

const Error = ({ isSuspense = false }) => {
  const [counter, setCounter] = useState(10);

  const onReload = useCallback(() => window.location.reload(), []);

  useEffect(() => {
    if (isSuspense) return () => null;

    const timer = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [counter, isSuspense, setCounter]);

  useEffect(() => {
    if (counter === 0) onReload();
  }, [counter, onReload]);

  return (
    <>
      <GlobalStyles />
      <Container>
        <Title>Ups :(</Title>
        <Text>
          {isSuspense
            ? 'Wir konnten keine Verbindung zum Server herstellen. Bitte überprüfen Sie Ihre Internetverbindung.'
            : 'Auf Ihrem Computer ist ein Problem aufgetreten. Wir haben die Fehlerinformationen gesammelt und anonym an unsere Fehleranalysesoftware übertragen. Wir werden versuchen, das Problem so schnell wie möglich zu beheben.'}
        </Text>
        <Restart type="button" onClick={onReload} disabled={!isSuspense}>
          {isSuspense ? 'Seite neu laden' : `Die Anwendung wird in ${counter}s neu gestartet.`}
        </Restart>
      </Container>
    </>
  );
};

Error.propTypes = { isSuspense: PropTypes.bool };

export default Error;

import React, { useMemo, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { ifElse, pipe, filter, propEq, join, map } from 'ramda';

import { useUser, FIRST_NAME, LAST_NAME } from 'store/session';
import { useChapter, INTRODUCTION, CHAPTERS, NAME, STATUS, TASK_STATUSES } from 'store/tasks';
import { WRITING } from 'store/writings';
import { ReactComponent as Logo } from 'assets/svg-icons/logo.svg';
import { ReactComponent as OutroIcon } from 'assets/svg-icons/outroIcon.svg';
import { ReactComponent as CompletedTaskIcon } from 'assets/svg-icons/completedTaskIcon.svg';
import { ReactComponent as TaskIcon } from 'assets/svg-icons/taskIcon.svg';

import { ICONS } from './consts';
import { useNavChecker } from './hooks';
import { Container, NavLink, Content, ContentWrap, Button, Wrapper, Item, Footer } from './styles';

const Navbar = () => {
  const { pathname } = useLocation();
  const { task, index } = useParams();
  const navigate = useNavigate();
  const part = useMemo(
    () =>
      (new RegExp(`^\\/${INTRODUCTION}`).test(pathname) && INTRODUCTION) ||
      (new RegExp(`^\\/${WRITING}`).test(pathname) && WRITING) ||
      null,
    [pathname]
  );
  const { t } = useTranslation('course');
  const parts = useMemo(() => t('$parts', { returnObjects: true }), [t]);
  const { ref, isOpened, onHoverStart, onHoverEnd, onTapStart } = useNavChecker();
  const [firstName, lastName] = useUser([FIRST_NAME, LAST_NAME]);
  const fullName = useMemo(
    () =>
      pipe(
        filter(Boolean),
        ifElse(
          () => isOpened,
          join(' '),
          pipe(
            map((name) => name[0].toUpperCase()),
            join('')
          )
        )
      )([firstName, lastName]),
    [firstName, lastName, isOpened]
  );
  const chapter = useChapter(task);
  const isCompleted = propEq(TASK_STATUSES.COMPLETED, STATUS, chapter || null);
  const completedTasks = useMemo(
    () =>
      chapter
        ? chapter[CHAPTERS].reduce(
            (acc, { [NAME]: name, [STATUS]: status }) => ({ ...acc, [name]: status === TASK_STATUSES.COMPLETED }),
            {}
          )
        : {},
    [chapter]
  );
  const handlePart = useCallback(({ currentTarget }) => navigate(`/${currentTarget.value}`), [navigate]);
  const handleTask = useCallback(
    ({ currentTarget }) => navigate(['', currentTarget.dataset.part, currentTarget.value, 0].join('/')),
    [navigate]
  );
  const handleIndex = useCallback(
    ({ currentTarget }) => navigate(['', currentTarget.dataset.part, currentTarget.dataset.task, currentTarget.value].join('/')),
    [navigate]
  );

  return (
    <Container ref={ref} onHoverStart={onHoverStart} onHoverEnd={onHoverEnd} onPointerDown={onTapStart} $isOpened={isOpened}>
      <NavLink to="/" $isMenuOpened={isOpened}>
        <Logo />
      </NavLink>
      <Content $isMenuOpened={isOpened}>
        {parts.map(({ id, name, tasks }) => (
          <ContentWrap key={id} $withLine={id === 'introduction'}>
            <Button type="button" value={id} onClick={handlePart} $icon={ICONS[id]} $isActive={part === id}>
              {name}
            </Button>
            <Wrapper $isOpened={part === id || pathname === '/'}>
              {tasks.map(({ id: taskId, name: taskName, menu }) => (
                <Fragment key={taskId}>
                  <Button
                    type="button"
                    data-part={id}
                    value={taskId}
                    onClick={handleTask}
                    $icon={ICONS[taskId]}
                    disabled={task === taskId}
                  >
                    {taskName}
                  </Button>
                  <Wrapper $isOpened={task === taskId}>
                    {menu.map(({ id: itemId, name: itemName }, i) => (
                      <Item
                        key={itemId}
                        type="button"
                        data-part={id}
                        data-task={taskId}
                        value={i}
                        onClick={handleIndex}
                        disabled={i === Number(index)}
                        $isCompleted={completedTasks[itemId] || isCompleted}
                      >
                        {(itemId === 'outro' && <OutroIcon />) || (completedTasks[itemId] ? <CompletedTaskIcon /> : <TaskIcon />)}
                        <span>{itemName}</span>
                      </Item>
                    ))}
                  </Wrapper>
                </Fragment>
              ))}
            </Wrapper>
          </ContentWrap>
        ))}
      </Content>
      <Footer to="/profile" $isMenuOpened={isOpened}>
        {fullName}
      </Footer>
    </Container>
  );
};

export default Navbar;

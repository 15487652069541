import React, { useCallback } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useUser, useLoggedIn, LANGUAGE } from 'store/session';
import LangSelect from 'components/LangSelect';
import { ReactComponent as LogoIcon } from 'assets/svg-icons/logo.svg';
import { ReactComponent as HelpIcon } from 'assets/svg-icons/help.svg';

import { LANGUAGES } from './consts';
import { Container, Wrapper, Select, Content, NavLink } from './styles';

const Header = ({ isFixed = false, maxWidth = 1060, className, children = null }) => {
  const { t } = useTranslation('common');
  const isLoggedIn = useLoggedIn();
  const isHomePage = useMatch('/');
  const isProfilePage = useMatch('/profile');
  const userLanguage = useUser(LANGUAGE);
  const onSelect = useCallback(() => {}, []);

  return (
    <Container $isFixed={isFixed} className={className}>
      <Wrapper $maxWidth={maxWidth} $isLoggedIn={isLoggedIn} $isHomePage={isHomePage}>
        {!isLoggedIn && (
          <Link to="/">
            <LogoIcon />
          </Link>
        )}
        {isProfilePage ? (
          <Select options={LANGUAGES} value={userLanguage} onSelect={onSelect} placeholder={t('Select language')} />
        ) : (
          <LangSelect />
        )}
        <Content>{children}</Content>
        <NavLink to="/support" title={t('Support')} aria-label={t('Support')}>
          <HelpIcon />
        </NavLink>
      </Wrapper>
    </Container>
  );
};

Header.propTypes = {
  isFixed: PropTypes.bool,
  maxWidth: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Header;

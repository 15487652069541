import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { UPDATE_WRITING, RESET_WRITING, UPDATE_SEARCH, UPDATE_SORT, UPDATE_PAGINATION, RESET_FILTERS } from './types';
import {
  CURRENT,
  FILTERS,
  TITLE,
  PAGINATION,
  START_AFTER,
  LIMIT,
  COUNT,
  HAS_MORE,
  SORT,
  SORT_BY,
  SORT_DIR,
  MODIFIED_AT,
  DESC,
} from '.';

const init = {
  [CURRENT]: null,
  [FILTERS]: {
    [TITLE]: '',
    [PAGINATION]: {
      [START_AFTER]: 0,
      [LIMIT]: 8,
      [COUNT]: 0,
      [HAS_MORE]: false,
    },
    [SORT]: {
      [SORT_BY]: MODIFIED_AT,
      [SORT_DIR]: DESC,
    },
  },
};

export default combineReducers({
  [CURRENT]: handleActions(
    {
      [UPDATE_WRITING]: (state, { payload }) => ({ ...state, ...payload }),
      [RESET_WRITING]: () => null,
    },
    init[CURRENT]
  ),
  [FILTERS]: handleActions(
    {
      [UPDATE_SEARCH]: (state, { payload }) => ({
        ...state,
        [TITLE]: payload,
        [PAGINATION]: {
          ...state[PAGINATION],
          [START_AFTER]: 0,
        },
      }),
      [UPDATE_SORT]: (state, { payload }) => ({
        ...state,
        [SORT]: payload,
        [PAGINATION]: {
          ...state[PAGINATION],
          [START_AFTER]: 0,
        },
      }),
      [UPDATE_PAGINATION]: (state, { payload }) => ({
        ...state,
        [PAGINATION]: {
          ...state[PAGINATION],
          ...payload,
        },
      }),
      [RESET_FILTERS]: () => init[FILTERS],
    },
    init[FILTERS]
  ),
});

import styled from 'styled-components';

import icon from 'assets/svg-icons/selectIcon.svg';

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const Label = styled.span`
  font: var(--font16);
  color: var(--color-gray90);
`;

export const StyledSelect = styled.select`
  width: 100%;
  height: 36px;
  border-bottom: 1px solid;
  border-color: var(--color-gray80);
  font: var(--font16);
  color: var(--color-gray80);
  padding: 0 24px 0 0;
  background-image: url(${icon});
  background-position: right;
  background-repeat: no-repeat;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  &:disabled {
    opacity: 1;
    cursor: not-allowed;
  }
  &:focus {
    border-color: var(--color-blue80);
    color: var(--color-text100);
  }
`;

export const Message = styled.span`
  font: var(--font14M);
  color: var(--color-error);
`;

import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  SET_TASK,
  SET_TASKS,
  UPDATE_TASK,
  UPDATE_CHAPTERS_STATUS,
  FETCH_TASK,
  FETCH_TASKS,
  SAVE_TASK,
  SEND_ANSWERS,
} from './types';

export const setTask = createAction(SET_TASK);
export const setTasks = createAction(SET_TASKS);
export const updateTask = createAlertAction(UPDATE_TASK);
export const updateChapterStatus = createAction(UPDATE_CHAPTERS_STATUS);

export const fetchTask = createAlertAction(FETCH_TASK);
export const fetchTasks = createAlertAction(FETCH_TASKS);
export const saveTask = createAlertAction(SAVE_TASK);
export const sendAnswers = createAlertAction(SEND_ANSWERS);

import styled from 'styled-components';

export const Container = styled.div`
  flex: ${({ $flex }) => ($flex ? '1 1 auto' : '0 1 auto')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = styled.div`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 50%;
  border: ${({ $size }) => Math.round($size / 10)}px solid;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) ${({ $color }) => $color};
  animation: spin 1.3s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSessionActions, useAlerts, useAuth, useLoggedIn, useUser, ID } from 'store/session';
import Spinner from 'components/Spinner';

const InitApp = ({ children }) => {
  const { restore, logout } = useSessionActions();
  const { id, action, loading, error } = useAlerts(restore);
  const isAuth = useAuth();
  const isLoggedIn = useLoggedIn();
  const userId = useUser(ID);
  const shouldRestore = isAuth && !isLoggedIn && !userId;
  const isLoading = shouldRestore && (!id || loading);

  useEffect(() => {
    if (shouldRestore) action();
  }, [action, shouldRestore]);

  useEffect(() => {
    if (error) logout();
  }, [error, logout]);

  return isLoading ? <Spinner flex={1} /> : children;
};

InitApp.propTypes = { children: PropTypes.element.isRequired };

export default InitApp;

import { takeLeading, debounce, takeEvery, call, put, select } from 'redux-saga/effects';
import { omit, last } from 'ramda';

import { withAlert } from 'store/alerts';
import api from 'api';

import { FETCH_TASK, FETCH_TASKS, SAVE_TASK, UPDATE_TASK, UPDATE_CHAPTERS_STATUS, SEND_ANSWERS } from './types';
import { setTask, setTasks, saveTask as saveTaskAction } from './actions';
import { getChapter } from './selectors';
import { NAME, ID, ANSWERS, CHAPTERS } from '.';

function* fetchTasks() {
  const { data } = yield call(api.get, '/tasks');

  yield put(setTasks(data.reduce((acc, item) => ({ ...acc, [item[NAME]]: item }), {})));
}

function* fetchTask({ payload }) {
  const chapter = yield select(getChapter(payload));

  if (chapter) return { success: true };

  const { data } = yield call(api.get, '/tasks', { params: { [NAME]: payload } });

  if (!data?.length) {
    const task = yield call(api.post, '/tasks', { [NAME]: payload });

    yield put(setTask(task));

    return { success: true };
  }

  const latest = last(data);

  yield put(setTask(latest));

  return { success: true };
}

function* saveTask({ payload }) {
  const data = yield call(api.patch, `/tasks/${payload[ID]}`, omit([ID], payload));

  yield put(setTask(data));
}

function* updateTask({ payload }) {
  const chapter = yield select(getChapter(payload[NAME]));

  if (chapter) {
    yield put(saveTaskAction({ [ID]: chapter[ID], ...payload }));
  }
}

function* updateChapterStatus({ payload }) {
  const chapter = yield select(getChapter(payload[NAME]));

  if (chapter) {
    yield put(saveTaskAction({ [ID]: chapter[ID], [CHAPTERS]: chapter[CHAPTERS] }));
  }
}

function* sendAnswers({ payload }) {
  const chapter = yield select(getChapter(payload));

  if (chapter) {
    const data = yield call(api.post, `/tasks/${chapter[ID]}/solve`, { [ANSWERS]: chapter[ANSWERS] });

    yield put(setTask(data));
  }
}

export default function* watchTasks() {
  yield takeLeading(FETCH_TASKS, withAlert(fetchTasks));
  yield takeLeading(FETCH_TASK, withAlert(fetchTask));
  yield debounce(500, SAVE_TASK, withAlert(saveTask));
  yield takeEvery(UPDATE_TASK, withAlert(updateTask));
  yield takeEvery(UPDATE_CHAPTERS_STATUS, withAlert(updateChapterStatus));
  yield takeLeading(SEND_ANSWERS, withAlert(sendAnswers));
}

export const DEFAULT_ERROR =
  'Oops, ein Fehler ist aufgetreten. Helfen Sie uns, Ihr Benutzererlebnis zu verbessern und senden Sie einen Fehlerbericht.';

export const TOKEN = 'token';
export const ID = '_id';
export const EMAIL = 'email';
export const PASSWORD = 'password';

export const SORT = 'sort';
export const SORT_BY = 'sortBy';
export const SORT_DIR = 'sortDir';
export const ASC = 'asc';
export const DESC = 'desc';
export const PAGINATION = 'pagination';
export const START_AFTER = 'start_after';
export const NEXT = 'startAfter';
export const COUNT = 'count';
export const HAS_MORE = 'hasMore';
export const LIMIT = 'limit';
export const EXPAND = 'expand[]';
export const FIELDS = 'fields[]';

import { useRef, useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useNavChecker = () => {
  const ref = useRef();
  const { pathname } = useLocation();
  const isHomePage = pathname === '/';
  const isHomePageRef = useRef(isHomePage);
  isHomePageRef.current = isHomePage;
  const [isOpened, setOpened] = useState(pathname === '/');

  const onHoverStart = useCallback(() => setOpened(true), []);
  const onHoverEnd = useCallback(() => setOpened(isHomePageRef.current), []);
  const onTapStart = useCallback(() => {
    setOpened(true);

    const cb = (e) => {
      if (!ref.current?.contains?.(e.target)) {
        document.body.removeEventListener('pointerdown', cb);
        setOpened(isHomePageRef.current);
      }
    };

    document.body.addEventListener('pointerdown', cb);
  }, []);

  useEffect(() => {
    if (!isHomePage) return () => null;

    const position = [0, 0];
    const parent = ref.current;
    const cb = (e) => {
      position[0] = e.pageX;
      position[1] = e.pageY;
    };

    window.document.addEventListener('mousemove', cb);

    return () => {
      const elem = window.document.elementFromPoint(...position);

      if (!elem || !parent?.contains?.(elem)) setOpened(false);
    };
  }, [isHomePage]);

  return { ref, isOpened, onHoverStart, onHoverEnd, onTapStart };
};

import React, { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { usePageData, filterMeta } from './utils';

const Head = () => {
  const { i18n } = useTranslation();
  const { url, type, title, description, withoutIndexing } = usePageData();
  const openGraphTitle = useMemo(() => (title ? `Scientific Writing | ${title}` : 'Scientific Writing'), [title]);

  useEffect(() => {
    filterMeta.forEach((name) => {
      const selectors = document.querySelectorAll(name);

      selectors.forEach((elem) => {
        const fromHelmet = elem.getAttribute('data-react-helmet');

        if (!fromHelmet && selectors.length === 2) elem.remove();
      });
    });
  }, []);

  return (
    <Helmet titleTemplate="Scientific Writing | %s">
      <html lang={i18n.language} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={openGraphTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta name="twitter:title" content={openGraphTitle} />
      <meta name="twitter:description" content={description} />
      {withoutIndexing ? <meta name="robots" content="none" /> : <meta name="robots" content="follow,index" />}
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default Head;

import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_WRITING = getName('UPDATE_WRITING');
export const RESET_WRITING = getName('RESET_WRITING');
export const UPDATE_SEARCH = getName('UPDATE_SEARCH');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const RESET_FILTERS = getName('RESET_FILTERS');

export const FETCH_WRITINGS = getName('FETCH_WRITINGS');
export const FETCH_WRITING = getName('FETCH_WRITING');
export const CREATE_WRITING = getName('CREATE_WRITING');
export const SAVE_WRITING = getName('SAVE_WRITING');
export const SAVE_W_QUESTION = getName('SAVE_W_QUESTION');
export const SAVE_CHAPTER = getName('SAVE_CHAPTER');
export const REMOVE_WRITING = getName('REMOVE_WRITING');
export const DOWNLOAD_WRITING_DOCX = getName('DOWNLOAD_WRITING_DOCX');
export const AI_WRITERS = getName('AI_WRITERS');
export const COPY_WH_QUESTIONS = getName('COPY_WH_QUESTIONS');

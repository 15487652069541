import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import GlobalStyles from 'theme/GlobalStyles';

import { ROUTES } from './utils';
import Head from './Head';
import InitApp from './InitApp';
import SuspenseComponent from './SuspenseComponent';

const Root = () => (
  <>
    <GlobalStyles />
    <Head />
    <InitApp>
      <Routes>
        {ROUTES.map(({ key, layout: Layout, items }) =>
          Layout ? (
            <Route key={key} element={<Layout />}>
              {items.map(({ path, isPrivate, component, items: subItems }) => (
                <Route key={path} path={path} element={<SuspenseComponent component={component} isPrivate={isPrivate} />} />
              ))}
            </Route>
          ) : (
            items.map(({ path, component, isPrivate }) => (
              <Route
                key={path}
                path={path !== '/' ? path : undefined}
                index={path === '/'}
                element={<SuspenseComponent component={component} isPrivate={isPrivate} />}
              />
            ))
          )
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </InitApp>
  </>
);

export default Root;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ErrorPage from 'components/Error';

class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    if (error && error.name === 'ChunkLoadError') {
      return { hasError: true };
    }

    return null;
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError ? <ErrorPage isSuspense /> : children;
  }
}

ErrorBoundary.propTypes = { children: PropTypes.node.isRequired };

export default ErrorBoundary;

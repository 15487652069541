import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_WRITING,
  RESET_WRITING,
  UPDATE_SEARCH,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  RESET_FILTERS,
  FETCH_WRITING,
  FETCH_WRITINGS,
  CREATE_WRITING,
  SAVE_WRITING,
  SAVE_W_QUESTION,
  SAVE_CHAPTER,
  REMOVE_WRITING,
  DOWNLOAD_WRITING_DOCX,
  AI_WRITERS,
  COPY_WH_QUESTIONS,
} from './types';

export const updateWriting = createAction(UPDATE_WRITING);
export const resetWriting = createAction(RESET_WRITING);
export const updateSearch = createAction(UPDATE_SEARCH);
export const updateSort = createAction(UPDATE_SORT);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const resetFilters = createAction(RESET_FILTERS);

export const fetchWritings = createAlertAction(FETCH_WRITINGS);
export const fetchWriting = createAlertAction(FETCH_WRITING);
export const createWriting = createAlertAction(CREATE_WRITING);
export const saveWriting = createAlertAction(SAVE_WRITING);
export const saveWQuestion = createAlertAction(SAVE_W_QUESTION);
export const saveChapter = createAlertAction(SAVE_CHAPTER);
export const removeWriting = createAlertAction(REMOVE_WRITING);
export const downloadWritingDocx = createAlertAction(DOWNLOAD_WRITING_DOCX);
export const aiWriters = createAlertAction(AI_WRITERS);
export const copyWhQuestions = createAlertAction(COPY_WH_QUESTIONS);

export const FEATURE_NAME = 'writings';

export const CURRENT = 'current';
export const FILTERS = 'filters';

export const WRITING = 'writing';
export const SW_CREATE = 'sw-create';
export const SW_REWRITE = 'sw-rewrite';

export const NAME = 'name';
export const TITLE = 'title';
export const CREATED_AT = 'createdAt';
export const MODIFIED_AT = 'modifiedAt';
export const W_QUESTIONS = 'wQuestions';
export const CHAPTERS = 'chapters';
export const TEXT = 'text';
export const FORMATTED_TEXT = 'formattedText';
export const ITEM_ID = 'id';
export const CONTEXT = 'context';
export const SUGGESTION = 'suggestion';
export const ERROR_CODES = 'errorCodes';
export const AI_MODEL = 'aimodel';
export const CONTENT = 'content';
export const LANGUAGE = 'language';
export const STRUCTURE = 'structure';

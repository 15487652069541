import React from 'react';
import PropTypes from 'prop-types';

import { Container, Loader } from './styles';

const Spinner = ({ size = 50, color = 'var(--color-black)', flex = 0, className = null }) => (
  <Container $flex={flex} className={className}>
    <Loader $size={size} $color={color} />
  </Container>
);

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  flex: PropTypes.number,
  className: PropTypes.string,
};

export default Spinner;

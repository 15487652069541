import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { routesMapping } from '../utils';

const DEFAULT_DESCRIPTION =
  'The Scientific Writing Assistant is designed to help you get your research published. This project is a collaboration between the University of Zurich and medignition.';

export const usePageData = () => {
  const { pathname } = useLocation();
  const { title, description, withoutIndexing } = useMemo(() => routesMapping(pathname)?.meta || {}, [pathname]);

  return useMemo(
    () => ({
      url: `${process.env.REACT_APP_HOST}${pathname}`,
      type: 'website',
      title,
      description: description || DEFAULT_DESCRIPTION,
      withoutIndexing: Boolean(withoutIndexing) || process.env.REACT_APP_TYPE !== 'production',
    }),
    [description, pathname, title, withoutIndexing]
  );
};

export const filterMeta = [
  'meta[name=description]',
  "meta[property='og:type']",
  "meta[property='og:url']",
  "meta[property='og:title']",
  "meta[property='og:description']",
  "meta[name='twitter:title']",
  "meta[name='twitter:description']",
  'meta[name=robots]',
];

import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';

import image from 'assets/images/skyBackground.jpg';

export const GlobalStyles = createGlobalStyle`
  #root {
    align-items: flex-start;
    background-image: url(${image});
    background-position: 615px center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (max-width: 991px), (orientation: portrait) {
    #root {
      align-items: center;
      background: none;
    }    
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  max-width: 615px;
  padding: 25px 90px 0;
`;

export const HomeLink = styled(Link)`
  margin: 0 auto 0 0;
`;

export const SupportLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  min-width: 24px;
  min-width: 24px;
`;

export const Content = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 615px;
  padding: 45px 90px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 615px;
  padding: 0 90px 30px;
`;

export const Text = styled.p`
  font: var(--font16);
  color: var(--color-text100);
`;

const linkStyle = `
  font: var(--font16);
  color: var(--color-blue80);
  text-decoration: none;
  transition: color 0.3s;
  &:active {
    color: var(--color-blue30);
  }
  @media (hover) {
    &:hover {
      color: var(--color-blue30);
    }
  }
`;

export const A = styled.a`
  ${linkStyle}
`;

export const TextLink = styled(Link)`
  ${linkStyle}
`;

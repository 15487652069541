import styled from 'styled-components';

import SelectDef from 'components/Select';

export const Select = styled(SelectDef)`
  width: 55px;
  & > select {
    border: none;
  }
`;

import { combineReducers } from 'redux';

import { FEATURE_NAME as alerts } from 'store/alerts/consts';
import alertsReducer from 'store/alerts/reducer';

import { FEATURE_NAME as session } from 'store/session/consts';
import sessionReducer from 'store/session/reducer';

import { FEATURE_NAME as tasks } from 'store/tasks/consts';
import tasksReducer from 'store/tasks/reducer';

import { FEATURE_NAME as writings } from 'store/writings/consts';
import writingsReducer from 'store/writings/reducer';

export default combineReducers({
  [alerts]: alertsReducer,
  [session]: sessionReducer,
  [tasks]: tasksReducer,
  [writings]: writingsReducer,
});

import { wrapActions, wrapSelector, wrapMultiSelector } from 'store/utils';

import { getAuth, getLoggedIn, getUser, getUserProp } from './selectors';
import * as actions from './actions';

export const useSessionActions = wrapActions({ ...actions });

export const useAuth = wrapSelector(getAuth);
export const useLoggedIn = wrapSelector(getLoggedIn);
export const useUser = wrapMultiSelector(getUserProp, getUser);

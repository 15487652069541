import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE } from 'utils/consts';

const files = ['common', 'login', 'course', 'writing'];

const resources = {
  en: files.reduce((acc, key) => {
    acc[key] = require(`./en/${key}.json`); // eslint-disable-line import/no-dynamic-require
    return acc;
  }, {}),
  // de: files.reduce((acc, key) => {
  //   acc[key] = require(`./de/${key}.json`); // eslint-disable-line import/no-dynamic-require
  //   return acc;
  // }, {}),
};

i18n.use(initReactI18next).init({
  resources,
  defaultNs: 'common',
  lng: DEFAULT_LANGUAGE,
  keySeparator: false,
  nsSeparator: ':',
  interpolation: { escapeValue: false },
  debug: process.env.REACT_APP_TYPE !== 'production',
});

export default i18n;

import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useLoggedIn } from 'store/session';
import Spinner from 'components/Spinner';

import ErrorBoundary from './ErrorBoundary';

const SuspenseComponent = ({ component: Component, isPrivate = false }) => {
  const isLoggedIn = useLoggedIn();

  if (isPrivate && !isLoggedIn) return <Navigate to="/login" replace />;

  return (
    <ErrorBoundary>
      <Suspense fallback={<Spinner flex={1} />}>
        <Component />
      </Suspense>
    </ErrorBoundary>
  );
};

SuspenseComponent.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isPrivate: PropTypes.bool,
};

export default SuspenseComponent;

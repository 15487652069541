import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const SET_TASK = getName('SET_TASK');
export const SET_TASKS = getName('SET_TASKS');
export const UPDATE_TASK = getName('UPDATE_TASK');
export const UPDATE_CHAPTERS_STATUS = getName('UPDATE_CHAPTERS_STATUS');

export const FETCH_TASK = getName('FETCH_TASK');
export const FETCH_TASKS = getName('FETCH_TASKS');
export const SAVE_TASK = getName('SAVE_TASK');
export const SEND_ANSWERS = getName('SEND_ANSWERS');

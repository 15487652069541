import 'react-app-polyfill/stable';
import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'locale';
import store from 'store';
import Root from 'components/Root';
import FallbackComponent from 'components/Error';
import packageJson from '../package.json';

const allowBugSnag = process.env.REACT_APP_TYPE === 'production' && process.env.REACT_APP_BUGSNAG_API_KEY;
if (allowBugSnag) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    appType: 'client',
    appVersion: packageJson.version,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
  });
}
const ErrorBoundary = allowBugSnag ? Bugsnag.getPlugin('react').createErrorBoundary(React) : Fragment;
const bugsnagProps = allowBugSnag ? { FallbackComponent } : {};

createRoot(document.getElementById('root')).render(
  <ErrorBoundary {...bugsnagProps}>
    <Provider store={store}>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);

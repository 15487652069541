export const FEATURE_NAME = 'course';

export const INTRODUCTION = 'introduction';
export const STRUCTURE = 'sw-structure';
export const W_QUESTION = 'sw-w-questions';
export const COOKBOOK = 'sw-cookbook';
export const EQUATOR_NETWORK = 'sw-equator-network';
export const REPORTING_GUIDLINES = 'sw-reporting-guidlines';
export const SEARCH_LITERATURE = 'sw-search-literature';

export const NAME = 'name';
export const STATUS = 'status';
export const ANSWERS = 'answers';
export const CHAPTERS = 'chapters';

export const TASK_STATUSES = {
  PENDING: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
};

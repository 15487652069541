import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoIcon } from 'assets/svg-icons/logo.svg';
import { ReactComponent as SupportIcon } from 'assets/svg-icons/help.svg';
import LangSelect from 'components/LangSelect';

import { GlobalStyles, Header, HomeLink, SupportLink, Content, Footer, Text, A, TextLink } from './styles';

const SkyLayout = () => {
  const { t } = useTranslation('common');
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <>
      <GlobalStyles />
      <Header>
        <HomeLink to="/">
          <LogoIcon />
        </HomeLink>
        <LangSelect />
        <SupportLink to="/support" title={t('Support')} aria-label={t('Support')}>
          <SupportIcon />
        </SupportLink>
      </Header>
      <Content>
        <Outlet />
      </Content>
      <Footer>
        <Text>
          © {year}{' '}
          <A href="https://medignition.com/" target="_blank" rel="noreferrer">
            medignition AG
          </A>
        </Text>

        <TextLink to="/support">{t('Support')}</TextLink>
      </Footer>
    </>
  );
};

export default SkyLayout;

import { createGlobalStyle } from 'styled-components';

// import helveticaNeueWoff from 'assets/fonts/helveticaneue.woff';
// import helveticaNeueWoff2 from 'assets/fonts/helveticaneue.woff2';
import helveticaNeueRoman from 'assets/fonts/helveticaNeueRoman.otf';

// @font-face {
//   font-display: swap;
//   font-family: HelveticaNeue;
//   src: url(${helveticaNeueWoff}) format("woff"), url(${helveticaNeueWoff2}) format("woff2");
// }

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-display: swap;
    font-family: HelveticaNeue;
    src: url(${helveticaNeueRoman}) format("otf");
  }
  html {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: var(--min-width-app);
  }
  body, #root {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  input, select {
    border: none;
    background: none;
    outline: none;
  }
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: none;
    box-sizing: border-box;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  :root {
    --color-black: #000;
    --color-white: #fff;
    --color-white-rgb: 255, 255, 255;
    --color-text100: #212529;
    --color-gray90: #343a40;
    --color-gray80: #495057;
    --color-gray70: #868e96;
    --color-gray60: #adb5bd;
    --color-gray60-rgb: 173, 181, 189;
    --color-gray50: #ced4da;
    --color-gray40: #dee2e6;
    --color-gray30: #e9ecef;
    --color-gray20: #f1f3f5;
    --color-gray10: #f8f9fa;
    --color-blue100: #004390;
    --color-blue80: #1d5af6;
    --color-blue80-rgb: 29, 90, 246;
    --color-blue60: #3f75ff;
    --color-blue50: #4a9dfd;
    --color-blue50-rgb: 74, 157, 253;
    --color-blue30: #99c6f9;
    --color-blue20: #cce2fc;
    --color-blue10: #f8fbff;
    --color-green: #34c759;
    --color-green-rgb: 52, 199, 89;
    --color-error: #ff3b30;
    --color-error-rgb: 255, 59, 48;
    --color-warn: #ff9500;
    --color-orange: #ff7a00;
    --color-orange-rgb: 255, 122, 0;
    --color-purple: #845ef7;
    --color-purple-rgb: 132, 94, 247;
    --color-yellow: #fab005;
    --color-yellow-rgb: 250, 176, 5;
    --color-lime: #c0ca33;
    --color-lime-rgb: 192, 202, 51;
    --color-info: #fc0;

    --font140: 300 140px/140px "HelveticaNeue", sans-serif;
    --font96: 300 96px/86.4px "HelveticaNeue", sans-serif;
    --font70: 300 70px/70px "HelveticaNeue", sans-serif;
    --font64: 300 64px/60.5px "HelveticaNeue", sans-serif;
    --font48M: 400 48px/48px "HelveticaNeue", sans-serif;
    --font40M: 400 40px/40px "HelveticaNeue", sans-serif;
    --font36: 300 36px/36px "HelveticaNeue", sans-serif;
    --font36M: 400 36px/36px "HelveticaNeue", sans-serif;
    --font36B: 600 36px/36px "HelveticaNeue", sans-serif;
    --font32B: 600 32px/36px "HelveticaNeue", sans-serif;
    --font28: 300 28px/33.6px "HelveticaNeue", sans-serif;
    --font28B: 600 28px/36px "HelveticaNeue", sans-serif;
    --font24: 300 24px/32px "HelveticaNeue", sans-serif;
    --font24M: 400 24px/24px "HelveticaNeue", sans-serif;
    --font24B: 600 24px/32px "HelveticaNeue", sans-serif;
    --font20B: 600 20px/28px "HelveticaNeue", sans-serif;
    --font18: 300 18px/20.25px "HelveticaNeue", sans-serif;
    --font18M: 400 18px/18px "HelveticaNeue", sans-serif;
    --font16: 300 16px/20px "HelveticaNeue", sans-serif;
    --font16M: 400 16px/20px "HelveticaNeue", sans-serif;
    --font16B: 600 16px/20px "HelveticaNeue", sans-serif;
    --font14: 300 14px/20px "HelveticaNeue", sans-serif;
    --font14M: 400 14px/20px "HelveticaNeue", sans-serif;
    --font13M: 400 13px/20px "HelveticaNeue", sans-serif;
    --font12M: 400 12px/12px "HelveticaNeue", sans-serif;
    --font12B: 600 12px/12px "HelveticaNeue", sans-serif;
/* 
    --font140: 400 140px/140px "HelveticaNeue", sans-serif;
    --font96: 400 96px/86.4px "HelveticaNeue", sans-serif;
    --font70: 400 70px/70px "HelveticaNeue", sans-serif;
    --font64: 400 64px/60.5px "HelveticaNeue", sans-serif;
    --font48M: 500 48px/48px "HelveticaNeue", sans-serif;
    --font40M: 500 40px/40px "HelveticaNeue", sans-serif;
    --font36: 400 36px/36px "HelveticaNeue", sans-serif;
    --font36M: 500 36px/36px "HelveticaNeue", sans-serif;
    --font36B: 700 36px/36px "HelveticaNeue", sans-serif;
    --font32B: 700 32px/36px "HelveticaNeue", sans-serif;
    --font28: 400 28px/33.6px "HelveticaNeue", sans-serif;
    --font28B: 700 28px/36px "HelveticaNeue", sans-serif;
    --font24: 400 24px/32px "HelveticaNeue", sans-serif;
    --font24B: 700 24px/32px "HelveticaNeue", sans-serif;
    --font20B: 700 20px/28px "HelveticaNeue", sans-serif;
    --font18: 400 18px/20.25px "HelveticaNeue", sans-serif;
    --font18M: 500 18px/18px "HelveticaNeue", sans-serif;
    --font16: 400 16px/20px "HelveticaNeue", sans-serif;
    --font16M: 500 16px/20px "HelveticaNeue", sans-serif;
    --font16B: 700 16px/20px "HelveticaNeue", sans-serif;
    --font14: 400 14px/20px "HelveticaNeue", sans-serif;
    --font14M: 500 14px/20px "HelveticaNeue", sans-serif;
    --font13M: 500 13px/20px "HelveticaNeue", sans-serif;
    --font12M: 500 12px/12px "HelveticaNeue", sans-serif;
    --font12B: 700 12px/12px "HelveticaNeue", sans-serif; */

    --shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    --border-radius: 8px;

    --header-height: 64px;
    --min-width-app: 768px;
  }
`;

export default GlobalStyles;
